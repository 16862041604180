import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import { Container, Wrapper } from './header.css';
import Menu from './menu';

// Example of a component-specific page transition
// const AnimatedContainer = posed.div({
//   enter: {
//     y: 0,
//     transition: {
//       ease: 'easeInOut',
//     },
//   },
//   exit: {
//     y: '-100%',
//     transition: {
//       ease: 'easeInOut',
//     },
//   },
// });

const Header = () => {
  const [scrollPos, setScrollPos] = useState(0);
  const [show, setShow] = useState(true);

  // const scrollEl = useRef(null);

  useEffect(() => {
    // const el = scrollEl.current;

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollPos]);

  const handleScroll = () => {
    setShow(document.body.getBoundingClientRect().top >= scrollPos);
    setScrollPos(document.body.getBoundingClientRect().top);
  };

  return (
    <Wrapper>
      <Menu show={show} />
    </Wrapper>
  );
};

export default Header;
