import { createGlobalStyle } from 'styled-components';
import { accent } from 'constants/theme';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0px;
    padding: 0;
    border: 0;
    font-size: 100%;
    // font: inherit;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
    box-sizing: border-box;
  }

  *, *::before, *::after {
    @import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');
    box-sizing: inherit;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont,"Karla", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1;
    font-size: 1.6rem;
    color: #000;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }

  ul, ol {
    margin: 0 0 2em 0;

    li {
      margin-left: 2em;
      position: relative;
    }
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  blockquote {
    font-size: inherit;
    // font-family: "Helvetica";
    padding: 0 0 0 100px;
    margin: 1.25em 0 1.4em 0;
    min-height: 60px;
    background: url("https://www.brainpickings.org/wp-content/themes/brainpickings/images/sprites.png") 0 -73px no-repeat;
  }

  blockquote p {
    display: inline;
    line-height: 1.6em;
    margin: 0 0 1.4em 0;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  
  a {
    color: black;
    text-decoration: none;
  }
  
  &:visited {
    color: black;
  }

  pre {
    display: block;
    padding: 2rem;
    margin-top: 4rem;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 5px;
    color: ${accent};
    border: 1px solid #ddd;
    font-family: "SFMono-Regular",Consolas,"Liberation Mono",Menlo,Courier,monospace;
  }

  video {
    max-width: 100%;
  }

  p {
    margin-bottom: 2rem;
  }

`;
