import React from 'react';
import styled from 'styled-components'
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container, Wrapper } from './header.css';
import Nav from 'components/header/nav';

const Menu = ({ show }) => {
  return (
    <Container className={show ? 'active' : 'hidden'}>
      <Link to="/">
        <LogoTitle>AMY</LogoTitle>
      </Link>
      <Nav />
    </Container>
  );
};

const LogoTitle = styled.h1`
  font-size: 2em;
  color: black;
`
export default Menu;
