import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Container } from './nav.css';
import Hamburger from 'hamburger-react';

const Nav = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      {isOpen && (
        <Container open={isOpen}>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </Container>
      )}
      <Hamburger toggled={isOpen} toggle={setOpen} />
    </>
  );
};

export default Nav;
