import styled from 'styled-components';
// import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.div`
  .active {
    visibility: visible;
    transition: all 200ms ease-in;
  }

  .hidden {
    visibility: hidden;
    transition: all 200ms ease-in;
    transform: translate(0, -150%);
  }
`;

export const Container = styled.header`
  position: fixed;
  width: 95vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3em 0 3em 2em;
  z-index: 99;

  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`;
